import { OutboundLink } from "gatsby-plugin-google-analytics";
import React from "react";
import { Nav, NavItem } from "reactstrap";
import styled from "styled-components";
import { Facebook, Instagram } from "../library/Icons";
import { ExternalLink, NavLink as BaseLink } from "../library/Links";

// NOTE: get sale info from CMS/Shopify to make this a network thing
const showBanner = false;

const Wrapper = styled.div`
  display: none;
  position: fixed;
  top: ${() => `${showBanner ? 100 : 70}px`};
  width: 100%;
  height: ${() => `calc(100% - ${showBanner ? 100 : 70}px)`};
  overflow: auto;
  background-color: white;
  text-align: center;
  padding: 96px 33px 102px;
  z-index: 16;
`;

const LinkContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LinkNav = styled(Nav)`
  margin: 0;
`;

const NavLink = styled(BaseLink)`
  font-family: Quarto;
  font-weight: normal;
  font-size: 36px;
  line-height: 59px;
  text-decoration: none;
  padding-top: 0;
  padding-bottom: 0;

  &&& {
    color: black;

    :hover {
      color: #bce0f6;
    }
  }
`;

const MusicLink = styled(ExternalLink)`
  font-family: Quarto;
  font-weight: normal;
  font-size: 36px;
  line-height: 59px;
  text-decoration: none;
  padding-top: 0;
  padding-bottom: 0;

  &&& {
    color: black;

    :hover {
      color: #bce0f6;
    }
  }
`;

const SocialIcons = styled.div`
  margin-bottom: 17px;
`;

const SocialLink = styled(OutboundLink).attrs(() => ({
  target: "_blank",
  rel: "noreferrer"
}))`
  margin: 0 7px;

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }
`;

const LegalText = styled.a`
  font-size: 10px;
  line-height: 25px;
`;

const MobileMenu = React.memo(({ closeMenu }: { closeMenu: (override?: boolean) => void }) => (
  <Wrapper id="mobile-nav">
    <LinkContainer>
      <LinkNav vertical>
        <NavItem>
          <NavLink to="/products" onClick={() => closeMenu()}>
            Buy Products
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/ourstory" onClick={() => closeMenu(false)}>
            Our Story
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/instructions" onClick={() => closeMenu(false)}>
            How To
          </NavLink>
        </NavItem>
        <NavItem>
          <MusicLink href="https://music.getwavyblue.com">Vibe With Us</MusicLink>
        </NavItem>
      </LinkNav>
      <div>
        <SocialIcons>
          <SocialLink href="https://www.facebook.com/getwavyblue">
            <Facebook color="black" />
          </SocialLink>
          <SocialLink href="https://www.instagram.com/getwavyblue">
            <Instagram color="black" />
          </SocialLink>
        </SocialIcons>
        <LegalText>Privacy Policy</LegalText>
        {" | "}
        <LegalText>Terms & Conditions</LegalText>
      </div>
    </LinkContainer>
  </Wrapper>
));

export default MobileMenu;
