import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import React, { useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import { Actions } from "../../actions/actions";
import useGlobalModal from "../../hooks/useGlobalModal";
import useMobile from "../../hooks/useMobile";
import WavyApi from "../../utils/api";
import { validateEmail } from "../../utils/form";
import { Facebook as Fb, Instagram as Insta, Logo } from "../library/Icons";
import Input from "../library/Input";
import { ExternalLink as External, NavLink } from "../library/Links";
import { Text } from "../library/Text";
import Alert from "./Alert";

const StyledFooter = styled.footer`
  color: white;
  background: #070d0f;
`;

const Content = styled.div`
  max-width: 986px;
  margin: 0 auto;
`;

const LogoRow = styled.div`
  padding: 115px 0;
  text-align: center;

  @media screen and (max-width: 768px) {
    padding: 60px 36px;
  }
`;

const Wrapper = styled(Container)`
  @media screen and (max-width: 768px) {
    padding: 0 36px;
  }
`;

const LinksCol = styled(Col)`
  @media screen and (max-width: 768px) {
    order: 2;
    margin-bottom: 42px;
  }
`;

const EmailCol = styled(Col)`
  @media screen and (max-width: 768px) {
    order: 1;
    margin-bottom: 57px;
  }
`;

const Title = styled.div`
  font-family: Quarto;
  font-weight: bold;
  font-size: 31px;
  line-height: 37px;
  margin-bottom: 12px;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const Caption = styled(Text)`
  color: white;
  margin-bottom: 12px;
`;

const Form = styled.form`
  margin: 0;
`;

const Email = styled(Input)`
  .wavy-input,
  .wavy-input-addon-text {
    color: white;
    border-bottom: 1px solid #d3d3d3;
  }

  .wavy-input-addon-text {
    color: ${props => (props.addOnDisabled ? "#7b7b7b" : undefined)};
  }

  .wavy-input::placeholder {
    color: #d3d3d3;
  }

  &&& {
    .wavy-input:focus {
      color: white;
    }
  }

  @media screen and (max-width: 768px) {
    .wavy-input-addon-text {
      font-size: 12px;
    }
  }
`;

const LinkContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const LinkText = styled.div`
  cursor: pointer;
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;

  :hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
`;

const RouterLink = styled(NavLink)`
  font-size: 16px;
  line-height: 35px;

  :hover {
    text-decoration: underline;
  }

  &&& {
    padding: 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
`;

const ExternalLink = styled(External)`
  font-size: 16px;
  line-height: 35px;

  :hover {
    text-decoration: underline;
  }

  &&& {
    padding: 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
`;

const IconContainer = styled.div`
  @media screen and (max-width: 768px) {
    text-align: center;
  }

  @media screen and (min-width: 769px) {
    margin-top: 34px;
  }
`;

const Facebook = styled(Fb)`
  cursor: pointer;
  margin-right: 27px;
`;

const Instagram = styled(Insta)`
  cursor: pointer;
`;

const LastRow = styled.div`
  color: #d3d3d3;
  padding: 45px 0;
  font-size: 12px;
  line-height: 25px;
  text-align: center;

  @media screen and (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 20px;
  }
`;

const SocialLink = styled(OutboundLink).attrs(() => ({
  target: "_blank",
  rel: "noreferrer"
}))``;

const Footer = () => {
  const emailRef = useRef(null);
  const isMobile = useMobile();
  const dispatch = useGlobalModal();
  const [message, setMessage] = useState("{}");
  const [loading, setLoading] = useState(false);
  const showReturn = () => dispatch(Actions.SHOW_RETURN_POLICY);
  const showAlert = params => {
    setMessage(JSON.stringify({ ...params, id: Math.random() }));
  };
  const onSubmit = event => {
    event.preventDefault();
    if (!validateEmail(emailRef.current.value)) {
      showAlert({
        message: "Please enter a valid email address",
        messageType: "warning"
      });
      return;
    }

    setLoading(true);
    WavyApi.subscribeToNewsletter(emailRef.current.value)
      .then(message => {
        setLoading(false);
        showAlert({ message });
      })
      .catch(message => {
        setLoading(false);
        showAlert({ message, messageType: "danger" });
      });
  };

  return (
    <StyledFooter>
      <Content>
        <LogoRow>
          <Link to="/">
            <Logo width={145} height={29} />
          </Link>
        </LogoRow>
        <Wrapper fluid>
          <Row>
            <LinksCol md={6}>
              <Row>
                <Col xs={!isMobile ? 4 : undefined}>
                  <LinkContainer>
                    <li>
                      <RouterLink to="/products">Products</RouterLink>
                    </li>
                    <li>
                      <RouterLink to="/ourstory">Our Story</RouterLink>
                    </li>
                    <li>
                      <RouterLink to="/formula">Formula</RouterLink>
                    </li>
                    <li>
                      <RouterLink to="/instructions">How To</RouterLink>
                    </li>
                    <li>
                      <ExternalLink href="https://music.getwavyblue.com">Vibe With Us</ExternalLink>
                    </li>
                  </LinkContainer>
                  {isMobile ? null : (
                    <IconContainer>
                      <SocialLink href="https://www.facebook.com/getwavyblue">
                        <Facebook />
                      </SocialLink>
                      <SocialLink href="https://www.instagram.com/getwavyblue">
                        <Instagram />
                      </SocialLink>
                    </IconContainer>
                  )}
                </Col>
                <Col xs={!isMobile ? 8 : undefined}>
                  <LinkContainer>
                    <li>
                      <RouterLink to="/privacy">Privacy Policy</RouterLink>
                    </li>
                    <li>
                      <LinkText onClick={showReturn}>Return Policy</LinkText>
                    </li>
                    <li>
                      <RouterLink to="/terms">Terms & Conditions</RouterLink>
                    </li>
                  </LinkContainer>
                </Col>
              </Row>
            </LinksCol>
            <EmailCol md={6}>
              <Title>Groomed for Greatness</Title>
              <Caption>Stay up-to-date on the latest news from Wavy!</Caption>
              <Form onSubmit={onSubmit}>
                <Email
                  type="email"
                  placeholder="Email Address"
                  addOn="SUBMIT"
                  inputRef={emailRef}
                  addOnDisabled={loading}
                  onAddOnClick={onSubmit}
                />
              </Form>
              <Alert message={message} />
            </EmailCol>
          </Row>
        </Wrapper>
        {!isMobile ? null : (
          <IconContainer>
            <SocialLink href="https://www.facebook.com/getwavyblue">
              <Facebook />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/getwavyblue">
              <Instagram />
            </SocialLink>
          </IconContainer>
        )}
        <LastRow>© 2021 WavyBlue ✊🏾</LastRow>
      </Content>
    </StyledFooter>
  );
};

export default Footer;
