import React, { useCallback } from "react";
import { Nav } from "reactstrap";
import styled from "styled-components";
import Tracking from "../../utils/tracking";
import { Logo } from "../library/Icons";
import { NavLink } from "../library/Links";

// import NavDropdown from "./NavDropdown";

const LinkNav = styled(Nav)`
  margin: 0;

  @media screen and (max-width: 768px) {
    && {
      display: none;
    }
  }
`;

const NavItem = styled.li.attrs(() => ({
  className: "nav-item"
}))`
  margin-bottom: 0;
  display: flex;
  align-items: center;

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }
`;

// const ProductDropdown = styled(NavDropdown)`
//   .btn-secondary {
//     color: ${props => props.caretColor || undefined};
//   }
// `;

const MainMenu = React.memo(({ color }: { color: string }) => {
  const onClick = useCallback(() => {
    Tracking.fb("ViewContent");
  }, []);

  return (
    <LinkNav navbar>
      <NavItem>
        <NavLink to="/" onClick={onClick}>
          <Logo width={145} height={29} color={color} />
        </NavLink>
      </NavItem>
      <NavItem>
        {/* <ProductDropdown caretColor={color} /> */}
        <NavLink to="/products" onClick={onClick} $color={color}>
          Products
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/ourstory" onClick={onClick} $color={color}>
          Our Story
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/instructions" onClick={onClick} $color={color}>
          How To
        </NavLink>
      </NavItem>
    </LinkNav>
  );
});

export default MainMenu;
